@import "../../styles/includes";

.container {
  box-sizing: border-box;
  margin: $gallery-spacing auto 0;
  max-width: 1280px;
  padding: 0 $gallery-spacing;

  @media (min-width: 1315px) {
    padding: 0 0;
  }
}
