@import "../../styles/includes";

@mixin flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.container {
  @include flex-row;
  padding: 6px 12px;
}

.logo {
  align-items: center;
  display: flex;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

.img {
  display: flex;
  font-weight: 500;
}

.text {
  font-size: 1.4rem;
  font-weight: 420;
  letter-spacing: 0.2px;
  margin-left: 0.5rem;
}

.items {
  @include flex-row;
  margin-left: 42px;
}

.item {
  @include flex-row;
  font-size: 1.2rem;
  text-decoration: none;

  &:not(&:first-child) {
    margin-left: 2rem;
  }

  @include darkMode {
    color: #e3e3e3;
  }

  svg {
    margin-right: 0.5rem;
  }
}
