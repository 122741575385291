@import "../../styles/includes";

.grid {
  display: grid;
  gap: $gallery-spacing;
  grid-template-columns: 1fr;
  margin: $gallery-spacing auto 0;
  max-width: 1280px;
  // padding: 2rem;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.optionsBar {
  display: flex;
  flex-direction: row;

  label {
    margin-left: 1rem;
  }
}
